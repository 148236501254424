import React from 'react';
import {Helmet} from "react-helmet";


import HeaderV2 from "../../components/HeaderV2/HeaderV2";
import HoverCards from "./ConcreteHoverCards"
import Footer from "../../components/Footer/Footer";
import ContactUsBtn from "../../components/ContactUsBtn";

import "../../../styles/ServicesPages.scss";
import SEO from "../../components/SEO";
import {StaticImage} from "gatsby-plugin-image";


function ConcreteTesting() {

    return (
        <div>
            <SEO title={"Concrete Testing - QCTesting"} description={"List of Concrete test we conduct"} url={"www.qctesting.com.au/services/ConcreteTesting"}
                 image={"../../images/ConcreteTestingBackground.webp"} keywords={"construction testing, construction material testing, material testing, concrete testing, " +
                "concrete, laboratory testing, building material testing"}/>

            <div>
                <HeaderV2/>
                <div className={"servicesHead"}>
                    <StaticImage
                        className={"headImage"}
                        src={"../../images/ConcreteTestingBackground.webp"}
                        alt={"Person doing a test next to a qctesting ute with concrete plant in background"}
                        placeholder={"blurred"}
                        objectFit={"cover"}
                    />
                    <h1 className={"headerText"}> Concrete Testing </h1>
                </div>
                    <div className={"boxShadow"}/>
                    <h1 id={"g"}> Tests We Conduct </h1>
                        <HoverCards/>
                <Footer/>
                <div /* Fix Position Items  */ >
                    <ContactUsBtn className={"contactBtnPosition"} redirect={"Tel:0408623377"}/>
                </div>
            </div>
        </div>
    );
}

export default ConcreteTesting;
